import React from 'react';

import {
  Button, Input, Textarea, MaskedInput, Select,
  FormGroup, Radio, Checkbox, Tooltip, Section, Loader, Alert,
  Datepicker, Modal, ValidationMessage, Toast, PricingCard, AddFormSectionButton,
  ConfirmDeleteButton, Container, Footer, ActionsWrapper, NavSteps, FormSection, Row, Col,
  FontAwesomeIcon, IconsSolid, LinkWithIcon, HorizontalInfoCard,
  Collapse, IconsLight, Fab, ScrollableContainer,
} from '@jkhy/vsg-design-system';


// Init toast
Toast.configure();

// Demo toasts
const notifySuccess = () => Toast.success('Success message here!');
const notifySuccessTime = () => Toast.success('Success message here!', {
  autoClose: false
});
const notifyDanger = () => Toast.danger('Danger message here!');

type AppState = { showFirstModal: boolean, showSecondModal: boolean, show: boolean, testDisabled: boolean, testLoading: boolean, collapseDemo3: boolean, testSteps: any[] }

class App extends React.Component<any, AppState> {
  private static readonly DOCS_HTML_CLASS = 'docs-body';
  
  private fileRef0: any = null;
  private fileRef1: any = null;

  constructor(props: any) {
    super(props);
    this.state = {
      showFirstModal: false,
      showSecondModal: false,
      show: false,
      testDisabled: false,
      testLoading: false,
      testSteps: [],
      collapseDemo3: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.updateDisabled = this.updateDisabled.bind(this);
    this.updateLoading = this.updateLoading.bind(this);
    this.updateTestSteps = this.updateTestSteps.bind(this);
    this.onTestNextStep = this.onTestNextStep.bind(this);
    this.onTestPrevStep = this.onTestPrevStep.bind(this);
  }

  componentDidMount() {
    const body = document.body;
    body.classList.add(App.DOCS_HTML_CLASS);
    // this.updateTestSteps();
    this.onTestNextStep();
  }

  updateTestSteps() {
    setTimeout(() => {
      this.setState({
        testSteps: [
          { text: 'Application Info', path: '#' },
          { text: 'Purpose Product', path: '#' },
          { text: 'Borrower', path: '#' },
          { text: 'Guarantor', path: '#' },
          { text: 'Reference', path: '#' },
          { text: 'Collateral', path: '#' },
          { text: 'Debts and Deposits Debts and Deposits Debts and Deposits', path: '/' },
          { text: 'Declarations', path: '/' }
        ]
      })
    }, 2000)
  }

  onTestNextStep() {
    // setTimeout(() => {
    //   const steps = [...this.state.testSteps];
    //   const testSteps = [
    //     { text: 'Application Info', path: '#', isActive: true },
    //     { text: 'Purpose Product', path: '#' },
    //     { text: 'Borrower', path: '#' },
    //     { text: 'Guarantor', path: '#' },
    //     { text: 'Reference', path: '#' },
    //     { text: 'Collateral', path: '#' },
    //     { text: 'Debts and Deposits Debts and Deposits Debts and Deposits', path: '/' },
    //     { text: 'Declarations', path: '/' }
    //   ];

    //   const activeIndex = steps.findIndex((el: any) => el.isActive === true);

    //   if (activeIndex === testSteps.length - 1) {
    //     return;
    //   }else if (activeIndex < steps.length - 1) {
    //     steps.forEach((step) => {
    //       step.isActive = false;
    //     });
    //     steps[activeIndex + 1].isActive = true;
    //     this.setState({
    //       testSteps: [
    //         ...steps,
    //       ]
    //     })
    //   } else if (steps.length < testSteps.length) {
    //     const newSteps = testSteps[steps.length];
    //     steps.forEach((step) => {
    //       step.isActive = false;
    //     });
    //     newSteps.isActive = true;

    //     this.setState({
    //       testSteps: [
    //         ...steps,
    //         newSteps
    //       ]
    //     })
    //   }
    // }, 10);

    const steps = [...this.state.testSteps];
    const testSteps = [
      { text: 'Application Info', path: '#', isActive: true },
      { text: 'Purpose Product', path: '#' },
      { text: 'Borrower', path: '#' },
      { text: 'Guarantor', path: '#' },
      { text: 'Reference', path: '#' },
      { text: 'Collateral', path: '#' },
      { text: 'Debts and Deposits Debts and Deposits Debts and Deposits', path: '/' },
      { text: 'Declarations', path: '/' }
    ];

    const activeIndex = steps.findIndex((el: any) => el.isActive === true);

    if (activeIndex === testSteps.length - 1) {
      return;
    } else if (activeIndex < steps.length - 1) {
      steps.forEach((step) => {
        step.isActive = false;
      });
      steps[activeIndex + 1].isActive = true;
      this.setState({
        testSteps: [
          ...steps,
        ]
      })
    } else if (steps.length < testSteps.length) {
      const newSteps = testSteps[steps.length];
      steps.forEach((step) => {
        step.isActive = false;
      });
      newSteps.isActive = true;

      this.setState({
        testSteps: [
          ...steps,
          newSteps
        ]
      })
    }
  }

  onTestPrevStep() {
    const steps = [...this.state.testSteps];

    if (steps.length > 0) {
      let activeIndex = steps.findIndex((el: any) => el.isActive === true);

      steps.forEach((step, index) => {
        if (activeIndex - 1 < 0) {
          activeIndex = 1;
        }

        if (activeIndex && index === activeIndex - 1) {
          step.isActive = true;
        } else {
          step.isActive = false;
        }
      });

      this.setState({
        testSteps: [
          ...steps
        ]
      })
    }
  }

  componentWillUnmount() {
    const body = document.body;
    body.classList.remove(App.DOCS_HTML_CLASS);
  }

  onModalSubmit() {
    console.log('Submit from modal');
  }

  updateDisabled() {
    this.setState({
      testDisabled: !this.state.testDisabled,
    })
  }

  updateLoading() {
    this.setState({
      testLoading: !this.state.testLoading,
    })
  }

  toggleModal(propName: string, value: any) {
    if (propName === 'showFirstModal') {
      this.setState({
        showFirstModal: !value
      });
    }
    if (propName === 'showSecondModal') {
      this.setState({
        showSecondModal: !value
      });
    }

  }

  onSubmitFile = (i: number) => {
    const fileRef = i === 0 ? this.fileRef0 : this.fileRef1;

    if (Math.floor(Math.random() * 11) % 2 === 0) {
      fileRef.onSuccess();
    } else {
      fileRef.onError('Error Message')
    }
  }

  render() {

    const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const creditCardMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

    const conditionsData = [
      { title: 'Monthly Payment', amount: '$3,151', description: ['(Principal & Interest)', <strong>(Second description)</strong>] },
      { title: 'Interest Rate', amount: '6.10%', description: ['(Fixed Rate)'] }
    ];
    const conditionsDataSecond = [
      { title: 'Monthly Payment', amount: '$3,151', description: ['(Principal & Interest)'] },
      { title: 'Interest Rate', amount: '6.10%', description: ['(Fixed Rate)'] },
      { title: 'Interest Rate', amount: '16.10%', description: ['(Fixed Rate)'] },
    ];
    const feesData = [
      { title: 'Application Fee', amount: '$300' },
      { title: 'Renewal Fee', amount: '$50' }
    ];

    return (
      <div>
        <Container>
          <h3 className="mb-4"><a href="/views/pages">Demo Pages</a></h3>
          <h1 className="mb-3">Design system</h1>
          <LinkWithIcon icon={IconsSolid.faPlus} >
            <a href="/" onClick={() => console.log('clicked link')}>test link</a>
          </LinkWithIcon>

          <LinkWithIcon icon={IconsSolid.faPlus} disabled>
            <a href="/" onClick={() => console.log('clicked link')}>test link</a>
          </LinkWithIcon>

          <FormGroup htmlFor="nameOne" className="mb-2" label="First Name">
            <Input type="text" name="nameOne" id="nameOne" />
          </FormGroup>
          <FormGroup htmlFor="age" label="Age" className="mb-2">
            <Input type="number" name="age" id="age" />
          </FormGroup>
          <FormGroup htmlFor="password" label="Password" className="mb-2">
            <Input type="password" name="password" id="password" />
          </FormGroup>
          <FormGroup htmlFor="disabledText" label="Disabled text" className="mb-2">
            <Input id="disabledText" name="disabledText" placeholder="Text disabled placeholder" disabled />
          </FormGroup>

          <FormGroup htmlFor="textArea" label="Text area" className="mb-2">
            <Textarea id="textArea" rows={4} />
          </FormGroup>
          <FormGroup htmlFor="textAreaD" label="Text area disabled" className="mb-2">
            <Textarea id="textAreaD" rows={4} disabled value="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
          </FormGroup>
          <Button className="mb-2 mr-2" type="button">default button is primary</Button>
          <Button className="mb-2 mr-2" btnType="primary" type="button">button</Button>
          <Button className="mb-2 mr-2" btnType="primary" type="button" disabled>button disabled</Button>
          <Button className="mb-2 mr-2" btnType="primary" type="submit">button with long text</Button>
          <Button className="mb-2 mr-2" btnType="secondary" type="button">button</Button>
          <Button className="mb-2 mr-2" btnType="secondary" type="button" disabled>button disabled</Button>
          <Button className="mb-2 mr-2" btnType="secondary" type="submit">button with long text</Button>
          <Button className='mb-2 mr-2' btnType="icon"><FontAwesomeIcon icon={IconsSolid.faTrash} /></Button>
          <h1 className="mb-2">Heading 1 - 4.75 rem</h1>
          <h2 className="mb-2">Heading 2 - 4.25 rem</h2>
          <h3 className="mb-2">Heading 3 - 3.75 rem</h3>
          <h4 className="mb-2">Heading 4 - 3.5 rem</h4>
          <h5 className="mb-2">Heading 5 - 3 rem</h5>
          <h6 className="mb-2">Heading 6 - 2.5 rem</h6>
          <p className="mb-2">Paragraph (body1 styles) - 2 rem</p>
          <p className="body1 mb-2">Body 1 - 2 rem</p>
          <p className="body2 mb-2">Body 2 - 1.75 rem</p>
          <label className="mb-2">Label - 1.85 rem</label>
          <h6 className="mb-2"><FontAwesomeIcon icon={IconsSolid.faPhone} /></h6>
          <p className="mb-2"><FontAwesomeIcon icon={IconsSolid.faDollarSign} /></p>
          <Row className="mb-2">
            <Col><h6>.col</h6></Col>
            <Col><h6>.col</h6></Col>
            <Col><h6>.col</h6></Col>
            <Col><h6>.col</h6></Col>
          </Row>
          <Row>
            <Col lg='3'>
              <FormGroup label="Phone mask" htmlFor="maskPhone" className="mb-2">
                <MaskedInput
                  id="maskPhone"
                  type='phone'
                  mask={usPhoneCountryCodeMask}
                  icon={IconsSolid.faPhoneAlt}
                />
              </FormGroup>
            </Col>
            <Col lg='3'>
              <FormGroup label="Number mask" htmlFor="maskNumber" className="mb-2">
                <MaskedInput
                  id="maskNumber"
                  type='number'
                  icon={IconsSolid.faDollarSign}
                />
              </FormGroup>
            </Col>
            <Col lg='3'>
              <FormGroup label="Percent mask" htmlFor="maskPercent" className="mb-2">
                <MaskedInput
                  id="maskPercent"
                  type='percent'
                  icon={IconsSolid.faPercent}
                />
              </FormGroup>
            </Col>
            <Col lg='3'>
              <FormGroup label="Card mask" htmlFor="maskCard" className="mb-2">
                <MaskedInput
                  id="maskCard"
                  mask={creditCardMask}
                  type='credit-card'
                  icon={IconsSolid.faCreditCard}
                />
              </FormGroup>
            </Col>
            <Col lg='3'>
              <FormGroup label="Zip mask" htmlFor="maskZip" className="mb-2">
                <MaskedInput
                  id="maskZip"
                  mask={zipCodeMask}
                  type='zipCode'
                  icon={IconsSolid.faMapPin}
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup htmlFor="selectOne" label="Select" className="mb-2">
            <Select
              placeholder="Select..."
              options={[
                { value: 'chocolate', label: 'Chocolate' },
                { value: 'strawberry', label: 'Strawberry' },
                { value: 'vanilla', label: 'Vanilla' }
              ]}
              id="selectOne"
            />
          </FormGroup>

          <Row>
            <Col sm="3">
              <FormGroup label="Candy" className="mb-2" htmlFor="candy">
                <Select
                  options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }
                  ]}
                  id="candy"
                />
              </FormGroup>
            </Col>
            <Col sm={{ size: 6, offset: 3 }}>
              <FormGroup label="Diabled Select" htmlFor="dSelect" className="mb-2" >
                <Select
                  id="dSelect"
                  disabled={this.state.testDisabled}
                  isLoading={this.state.testLoading}
                  options={[
                    { value: 'chocolate', label: 'Chocolate' }
                  ]}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg='4'>
              <Button onClick={this.updateDisabled} className="mb-2">Toggle Disabled</Button>
              <Button onClick={this.updateLoading}>Toggle Loading</Button>
            </Col>
            <Col lg='4'>
              <FormGroup label='Full Name' hoverHelp="Full name" htmlFor="fullName" isValid={true} validationMessage="Error message help.">
                <Input id="fullName" disabled={this.state.testDisabled} />
              </FormGroup>
            </Col>
            <Col lg='4'>
              <FormGroup label='Label' htmlFor="inputLable" className="mb-2">
                <Input id="inputLable" disabled />
              </FormGroup>
            </Col>
            <Col lg='4'>
              <FormGroup label='Description' htmlFor='description' className="mb-2">
                <Textarea id='description' />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg='4'>
              <FormGroup htmlFor="asdf" label='Credit card' className="mb-2">
                <MaskedInput
                  id="asdf"
                  mask={creditCardMask}
                  type='credit-card'
                  icon={IconsSolid.faCreditCard}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Tooltip title="Info text here" placement="right">
                <p className="d-inline-block">Tooltip right</p>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
                <p className="d-inline-block">Tooltip top</p>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Tooltip" placement="bottom">
                <p className="d-inline-block">Tooltip bottom</p>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Info text here" placement="left">
                <p className="d-inline-block">Tooltip left</p>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Radio className="mb-2" htmlFor="male" id="male" name="gender" value="male">Male</Radio>
            </Col>
            <Col>
              <Radio className="mb-2" htmlFor="female" id="female" name="gender" value="female">Female</Radio>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Radio className="mb-2" htmlFor="longradio1" id="longradio1" name="longradio" value="1">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</Radio>
            </Col>
            <Col>
              <Radio className="mb-2" htmlFor="longradio2" id="longradio2" name="longradio" value="2">Lorem Ipsum is simply dummy text</Radio>
            </Col>
          </Row>
          <Checkbox className="mb-1" htmlFor="vehicle1" id="vehicle1" name="vehicle1" value="Bike">I have a bike</Checkbox>
          <Checkbox className="mb-1" htmlFor="vehicle2" id="vehicle2" name="vehicle2" value="Car">I have a car</Checkbox>
          <Checkbox className="mb-2" htmlFor="vehicle3" id="vehicle3" name="vehicle3" value="Boat">I have a boat</Checkbox>
          <Row className="mb-3">
            <Col lg='3' className="mb-1">
              <Button onClick={() => { this.toggleModal('showFirstModal', this.state.showFirstModal) }} >Modal</Button>
              <Modal title="Modal example 1"
                onClose={() => { this.toggleModal('showFirstModal', this.state.showFirstModal) }}
                isVisible={this.state.showFirstModal}>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
              including versions of Lorem Ipsum</p>
              </Modal>
            </Col>
            <Col lg='3' className="mb-1">
              <Button onClick={() => { this.toggleModal('showSecondModal', this.state.showSecondModal) }} >Modal 2</Button>
              <Modal isVisible={this.state.showSecondModal}
                onClose={() => { this.toggleModal('showSecondModal', this.state.showSecondModal) }}
                parentClass='parent-modal-2'
                title="Modal example 2"
                onSubmit={this.onModalSubmit}
                closeBtnName='Close'
                actionBtnName='Agree'>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
               and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup label="Datepicker" htmlFor="dpDefault" className="mb-2">
                <Datepicker id="dpDefault" />
              </FormGroup>
            </Col>
          </Row>
          <Button className="mb-3 mr-2" onClick={notifySuccess}>Success toast</Button>
          <Button className="mb-3 mr-2" onClick={notifyDanger}>Danger toast</Button>
          <Button className="mb-3 mr-2" onClick={notifySuccessTime}>Success toast with custom props</Button>

          <Alert type="danger" text="Danger message here!" className="mb-2" />
          <Alert type="warning" text="Warning message here!" className="mb-2" />
          <Alert type="success" text="Success message here!" className="mb-2" />
          <Alert type="info" text="Info message here!" className="mb-2" />
          <Loader />
          <Section className="mb-4" title="Borrower Owner">
            <h6 className="text-center mb-2">Purpose and Product</h6>
            <table className="preview-table mb-4">
              <tbody>
                <tr>
                  <td><p>Purpose of Loan</p></td>
                  <td><p>Working Capital</p></td>
                </tr>
                <tr>
                  <td><p>Product Type</p></td>
                  <td><p>Credit Card</p></td>
                </tr>
                <tr>
                  <td><p>Amount Requested</p></td>
                  <td><p>$ 100,000</p></td>
                </tr>
                <tr>
                  <td><p>Specific Reason</p></td>
                  <td><p>new hotel</p></td>
                </tr>
                <tr>
                  <td><p>Location of Business</p></td>
                  <td><p>AK</p></td>
                </tr>
                <tr>
                  <td><p>County of Business</p></td>
                  <td><p>Aleutinas West Census Area</p></td>
                </tr>
                <tr>
                  <td><p>Existing Deposit Customer</p></td>
                  <td><p>Yes</p></td>
                </tr>
                <tr>
                  <td><p>Date Business Started</p></td>
                  <td><p>4/2016</p></td>
                </tr>
              </tbody>
            </table>
            <table className="preview-table">
              <tbody>
                <tr>
                  <td><p>Purpose of Loan</p></td>
                  <td><p>Working Capital</p></td>
                </tr>
                <tr>
                  <td><p>Product Type</p></td>
                  <td><p>Credit Card</p></td>
                </tr>
                <tr>
                  <td><p>Amount Requested</p></td>
                  <td><p>$ 100,000</p></td>
                </tr>
                <tr>
                  <td><p>Specific Reason</p></td>
                  <td><p>new hotel</p></td>
                </tr>
                <tr>
                  <td><p>Location of Business</p></td>
                  <td><p>AK</p></td>
                </tr>
                <tr>
                  <td><p>County of Business</p></td>
                  <td><p>Aleutinas West Census Area</p></td>
                </tr>
                <tr>
                  <td><p>Existing Deposit Customer</p></td>
                  <td><p>Yes</p></td>
                </tr>
                <tr>
                  <td><p>Date Business Started</p></td>
                  <td><p>4/2016</p></td>
                </tr>
              </tbody>
            </table>
          </Section>
          <Section className="mb-4" title="Borrower Owner">
            <h6 className="text-center mb-2">Purpose and Product</h6>
            <table className="preview-table">
              <tbody>
                <tr>
                  <td><p>Purpose of Loan</p></td>
                  <td><p>Working Capital</p></td>
                </tr>
                <tr>
                  <td><p>Product Type</p></td>
                  <td><p>Credit Card</p></td>
                </tr>
                <tr>
                  <td><p>Amount Requested</p></td>
                  <td><p>$ 100,000</p></td>
                </tr>
                <tr>
                  <td><p>Specific Reason</p></td>
                  <td><p>new hotel</p></td>
                </tr>
                <tr>
                  <td><p>Location of Business</p></td>
                  <td><p>AK</p></td>
                </tr>
                <tr>
                  <td><p>County of Business</p></td>
                  <td><p>Aleutinas West Census Area</p></td>
                </tr>
                <tr>
                  <td><p>Existing Deposit Customer</p></td>
                  <td><p>Yes</p></td>
                </tr>
                <tr>
                  <td><p>Date Business Started</p></td>
                  <td><p>4/2016</p></td>
                </tr>
              </tbody>
            </table>
          </Section>

          <h6 className="mb-5">Pricing Cards</h6>
          <Row>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }}>
              <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsDataSecond} />
            </Col>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }}>
              <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} selected />
            </Col>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }}>
              <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} />
            </Col>
            <div className="w-100"></div>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }}>
              <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} selected />
            </Col>
            <Col lg={{ size: 4, offset: 0 }} md={{ size: 8, offset: 2 }}>
              <PricingCard title="3 Year Term" amount="$100,000.00" feesData={feesData} conditionsData={conditionsData} />
            </Col>
          </Row>

          <form>
            <Row>
              <Col lg='5'>
                <FormGroup htmlFor='fNameInvalid' label='First Name' isValid={false} validationMessage="Invalid field">
                  <Input id="fNameInvalid" name="fname" />
                </FormGroup>
              </Col>
              <Col lg='4'>
                <FormGroup htmlFor='lNameInvalid' label='Last Name' isValid={false} validationMessage="Invalid field" >
                  <Input id="lNameInvalid" name="lname" />
                </FormGroup>
              </Col>
              <Col lg='4'>
                <FormGroup htmlFor="addInfo" label='Aditional info' validationMessage="Invalid field" isValid={false}>
                  <Textarea id="addInfo" />
                </FormGroup>
              </Col>
              <Col lg='4'>
                <FormGroup htmlFor="mobileMask" label='Phone' isValid={false} validationMessage="Invalid field">
                  <MaskedInput
                    id="mobileMask"
                    type='phone'
                    mask={usPhoneCountryCodeMask}
                    icon={IconsSolid.faPhoneAlt}
                  />
                </FormGroup>
              </Col>
              <Col lg='4'>
                <FormGroup label='Date' htmlFor="dpInvalid" isValid={false} validationMessage="Invalid field">
                  <Datepicker id="dpInvalid" />
                </FormGroup>
              </Col>
              <Col lg='4'>
                <FormGroup htmlFor="candyInvalid" label='Candy invalid' validationMessage="invalid" isValid={false}>
                  <Select
                    placeholder="Select..."
                    options={[
                      { value: 'chocolate', label: 'Chocolate' },
                      { value: 'strawberry', label: 'Strawberry' },
                      { value: 'vanilla', label: 'Vanilla' }
                    ]}
                    id="candyInvalid"
                  />
                </FormGroup>
              </Col>
              <Col lg='4'>
                <Radio htmlFor="male2" id="male2" name="gender" value="male">Male</Radio>
                <Radio htmlFor="female2" id="female2" name="gender" value="female">Female</Radio>
                <ValidationMessage message="Choose field" />
              </Col>
              <Col lg='4'>
                <Checkbox htmlFor="vehicle4" id="vehicle4" name="vehicle4" value="Bike">I have a bike</Checkbox>
                <Checkbox htmlFor="vehicle5" id="vehicle5" name="vehicle5" value="Car">I have a car</Checkbox>
                <Checkbox htmlFor="vehicle6" id="vehicle6" name="vehicle6" value="Boat">I have a boat</Checkbox>
                <ValidationMessage message="Choose field" />
              </Col>
            </Row>
          </form>

          <FormSection>
            <h3 className="mb-2">1. Business Deposit Relationship</h3>
            <FormGroup htmlFor="selDeposit" className="mb-2" label='Deposit Type'>
              <Select
                options={[
                  { value: 'moneyMarket', label: 'Money Market' },
                  { value: 'none', label: 'none' }
                ]}
                id="selDeposit"
              />
            </FormGroup>
            <FormGroup className="mb-2" label="Current Balance" htmlFor="maskBalance">
              <MaskedInput id="maskBalance" type="number" icon={IconsSolid.faDollarSign} />
            </FormGroup>
            <FormGroup className="mb-2" label="Bank Name" htmlFor="bankName" isRequired>
              <Input type="text" id="bankName" name="bName" />
            </FormGroup>
            <ConfirmDeleteButton confirmText="Are you sure you want to delete this obligation?" />
          </FormSection>

          <AddFormSectionButton tooltipText="Add another obligation">Add Obligation</AddFormSectionButton>
          <AddFormSectionButton
            tooltipText="Add another box"
            className="mb-6">Add Box</AddFormSectionButton>
          <h2>Nav steps</h2>
        </Container>
        <div className="mb-4">
          <NavSteps
            steps={this.state.testSteps}
          />
          <div className="w-100" style={{ height: '500px' }}>
            <div className="mt-2">
              <p>Content</p>
              {this.state.testSteps.length > 1 && <Button btnType="secondary" className="mr-1" onClick={this.onTestPrevStep}>Prev Step</Button>}
              <Button onClick={this.onTestNextStep}>Next Step</Button>
            </div>
          </div>
        </div>

        <Container>
          <p className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
          took a galley of type and scrambled it to make a type specimen book. It has survived not only five
          centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
          It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
              and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
          <Section title="Application Information">
            <Row>
              <Col lg="3" md="6">
                <FormGroup htmlFor="newBusiness" className="mb-2" label='Business (existing or new)'>
                  <Select
                    options={[
                      { value: 'newBusiness', label: 'New business' },
                      { value: 'existing', label: 'existing' }
                    ]}
                    id="newBusiness"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="6">
                <FormGroup className="mb-2" checkboxOrRadio label="Are you the Sole owner?">
                  <Radio htmlFor="genderyes2" id="genderyes2" name="gender" value="yes">Yes</Radio>
                  <Radio htmlFor="genderno2" id="genderno2" name="gender" value="no">No</Radio>
                </FormGroup>
              </Col>
            </Row>
          </Section>
          <Section>
            <p className="body-2 mb-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
            </p>
            <ActionsWrapper className="mb-1">
              <Button className="w-md-100 w-lg-auto">Primary</Button>
            </ActionsWrapper>
          </Section>
          <Footer  
             bottomText='© Copyright 2020. Powered by Jack Henry'
             version="Version 1.22.33.44"
          />

          <div className="mt-5"></div>

          <h4 className="mb-1">Horizontal info card</h4>
          <HorizontalInfoCard
            title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English"
            icon={IconsLight.faUser}
            description="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English"
            className="mb-2"
            onEdit={(e) => console.log(e.target)}
            onDelete={(e) => console.log(e.target)}
          />


          <HorizontalInfoCard
            title="Title"
            icon={IconsLight.faUser}
            className="mb-2"
            onEdit={(e) => console.log(e.target)}
            onDelete={(e) => console.log(e.target)}
          />

          <h6 className="mb-1 mt-4">Collapse</h6>
          <Row>
            <Col lg={4}>
              <Collapse
                title="Purchase:"
                subTitle="Very long text here Lorem Ipsum is simply dummy text"
                expanded={this.state.collapseDemo3}
                onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                className="mb-2"
              >
                <table className="preview-table mb-2">
                  <tbody>
                    <tr>
                      <td><p className="mb-1"><strong>First name:</strong></p></td>
                      <td><p className="mb-1">George</p></td>
                    </tr>
                    <tr>
                      <td><p className="mb-1"><strong>Middle name:</strong></p></td>
                      <td><p className="mb-1">Caskey</p></td>
                    </tr>
                    <tr>
                      <td><p className="mb-1"><strong>Last name:</strong></p></td>
                      <td><p className="mb-1">McCaskey</p></td>
                    </tr>
                    <tr>
                      <td><p className="mb-1"><strong>Telephone:</strong></p></td>
                      <td><p className="mb-1">555-555-1234</p></td>
                    </tr>
                    <tr>
                      <td><p className="mb-1"><strong>E-mail addess:</strong></p></td>
                      <td><p className="mb-1">mcaskey@chicagobears.com</p></td>
                    </tr>
                    <tr>
                      <td><p className="mb-1"><strong>Are you a U.S. Citizen?</strong></p></td>
                      <td><p className="mb-1">yes</p></td>
                    </tr>
                  </tbody>
                </table>
                <ActionsWrapper alignLeft narrow>
                  <LinkWithIcon icon={IconsSolid.faPen} >
                    <a href="/" >Go to borrower step to edit</a>
                  </LinkWithIcon>
                </ActionsWrapper>
              </Collapse>
            </Col>
          </Row>

          <h4>With set height</h4>
          <ScrollableContainer className="mb-2" height={{
            desktop: '10vh',
            tablet: '20vh',
            mobile: '30vh'
          }}>
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
          </ScrollableContainer>
          <h4>Default</h4>
          <ScrollableContainer
          >
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
          </ScrollableContainer>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <Fab />
      </div>
    );
  }
}

export default App;
