import React, { Component } from 'react';
import Highlight from 'react-highlight';

import PropVersionTag from '../PropVersionTag/PropVersionTag';
import { FileUploadCard, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

class FileUploadCardDocs extends Component {

    private fileUploadCardRef1 = null;
    private fileUploadCardRef2 = null;
    private fileUploadCardRef3 = null;

    private fileUploadCardRef4 = null;
    private fileUploadCardRef5 = null;
    private fileUploadCardRef6 = null;

    constructor(props: any) {
        super(props);
        this.state = {
            files: [
                {
                    file: new File(["file-from-db"], "file-from-db-uploaded.txt", {
                        type: "text/plain",
                    }),
                    uploadSuccessful: true,
                    guid: 'test-guid'
                },
                {
                    file: new File(["file-from-db-two"], "file-from-db-loading.txt", {
                        type: "text/plain",
                    }),
                    uploadSuccessful: false,
                    loading: true,
                },
                {
                    file: new File(["file-from-db-three"], "file-from-db-random-loading-progress.txt", {
                        type: "text/plain",
                    })
                },
            ] as {
                file: File,
                error: string,
                progress: number,
                uploadSuccessful: boolean,
                errorDescription: string,
                loading: boolean,
                guid: string | any
            }[],
        };
    }

    componentDidMount() {
        const copyState = { ...this.state } as any;
        if (copyState.files) {
            this.simulateUploading(copyState.files, this.fileUploadCardRef1);
        }

    }

    simulateUploading = (files: any, ref: any) => {

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.uploadSuccessful) {
                continue;
            }

            if (file.error) {
                const message = this.getErrorMessage(file.error, ref);

                // If file contains error call this function
                ref.onFileError(file, message.title, message.desc);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = file.progress || 0;

                let interval  = null as any;
                if (file.loading) {
                    ref.updateLoading(file, file.loading);
                }else{
                     interval = setInterval(() => {
                        progress += 1;
                        if (progress <= 100) {
                            if (!file.loading) {
                                ref.updateProgress(file, progress);
                            }
                        }
    
                    }, time / 100)
                }

                // If there error while uploading call this function
                // ref.onFileError(file, message.title, message.desc);

                setTimeout(() => {
                    clearInterval(interval);

                    // If success call this function to set file success
                    ref.onFileSuccess(file, `${file.file.name}-guid`);
                    
                    // Demo Simulate Uploading on server and after server check return error
                    // if (file.file.type === 'image/jpeg') {
                    //     ref.updateLoading(file, true);
                    //     setTimeout(()=>{
                    //         ref.updateLoading(file, false);
                    //         ref.onFileError(file, "SERVER ERROR");
                    //     }, 2000)
                    // }else{
                    //     ref.onFileSuccess(file, `${file.file.name}-guid`);
                    // }
                    
                }, time)
            }
        }

    }

    // Set custom Error messages
    getErrorMessage = (type: any, ref: any) => {
        switch (type) {
            case 'invalid name':
                return { title: 'Invalid file name.' }
            case 'exceeded maximum size':
                return { title: `File exceeds maximum file size (${ref.props.maxFileSizeMB || 50}MB.)` };
            case 'invalid type':
                return { title: 'Invalid file type.' }
            case 'pdf file is password protected':
                return { title: 'File is password protected', desc: 'Uploaded file appears to have a password protection. Please upload a file that is not password protected.' }
            default:
                return { title: 'Failed to upload' };
        }
    }

    handleOnChange = (files: any, ref: any, saveToState = false) => {
        const copyState = { ...this.state } as any;
        const mappedFiles = files.map((f: any) => { return { ...f } })
        let newFiles = files;
        if (saveToState) {
            newFiles = mappedFiles;
            this.setState({
                files: [...copyState.files, ...newFiles],
            })
        }
        this.simulateUploading(newFiles, ref);
    }

    handleOnSubmit = (ref: any, demoError?: boolean) => {
        // Get the files
        console.log(ref.state.files, ' onSubmit - files');

        // Simulated submit time
        setTimeout(() => {
            if (demoError) {
                // If error call this function with error message
                ref.onSubmitError('Internal Server Error please try again.');
            } else {
                // If success call this function
                ref.onSubmitSuccess('File was submitted.');
            }
        }, 2000)
    }


    render() {
        const { files } = this.state as any;
        console.log('ALL FILES', files);
        return <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> File Upload Card</h1>

            <h2 className="mb-2">Description</h2>
            <p className="mb-4">Custom FileUploadCard component, enables to file upload through browsing or drag and drop.</p>

            <h3 className="mb-2">Default type</h3>
            <div style={{ maxWidth: '383px' }}>
                <FileUploadCard
                    files={files}
                    className="mb-3"
                    title="Recurring document title goes here"
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    onRef={(ref: any) => (this.fileUploadCardRef1 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef1, true);
                    }}
                    onDelete={(file: any, removeWithAnimation: any) => {
                        const ref = this.fileUploadCardRef1 as any;

                        ref.updateLoading(file, true);
                        setTimeout(() => {
                            console.log('DELETED FILE:', file);
                            ref.updateLoading(file, false);

                            const updateOutherState = () => {
                                const { files } = this.state as any;
                                const saveFiles = [...files];
                                const currentFile = saveFiles.find((f) => f.file === file.file);
                                const currentFileIndex = saveFiles.indexOf(currentFile);
                                saveFiles.splice(currentFileIndex, 1);
    
                                this.setState({
                                    files: [...saveFiles]
                                });
                            }
                            removeWithAnimation(updateOutherState);
                        }, 5000);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef1)}
                />

                <FileUploadCard
                    className="mb-3"
                    title="Recurring document with error on submit"
                    details={[
                        { title: 'Required for:', maxVisibleTexts: 4, text: [
                            'Randall Enterprises',
                            'Randall Enterprises Holdings, L.L.C.',
                            'Allen Meriwether. Jr. Living Trust III, L.L.C.',
                            'Jacqueline Gladys Meriwether Trust L.L.C.',
                            'Allen Meriwether. Jr. Living Trust III',
                            'Allen Meriwether. Jr. 123',
                        ] },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef2 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef2);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef2, true)}
                    additionalInfoText='Each file must be less than 50MB. The total size of all files cannot be greater than 100MB.'
                />

                <FileUploadCard
                    className="mb-3"
                    cardErrorMessage="Reccuring Document was declined. Please upload a new file."
                    title="LV document"
                    details={[
                        { title: 'Declined on:', text: '15/10/2019', isInvalid: true },
                        { title: 'Required for:', text: 'FMDEF LS TEST CZAR' },
                        { title: 'Description:', text: 'This pre. doc will be sent to the Portal if the business party = TRUE.' },
                    ]}
                    // removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef3 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef3);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef3)}
                />
            </div>

            <h3 className="mb-2">Compact type</h3>
            <div style={{ maxWidth: '580px' }}>
                <FileUploadCard
                    className="mb-3"
                    title="Recurring document title goes here"
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef4 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef4);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef4)}
                    type="compact"
                />

                <FileUploadCard
                    className="mb-3"
                    title="Recurring document with error on submit"
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef5 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef5);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef5, true)}
                    type="compact"
                />

                <FileUploadCard
                    className="mb-3"
                    cardErrorMessage="Reccuring Document was declined. Please upload a new file."
                    title="LV document"
                    details={[
                        { title: 'Declined on:', text: '15/10/2019', isInvalid: true },
                        { title: 'Required for:', text: 'FMDEF LS TEST CZAR' },
                        { title: 'Description:', text: 'This pre. doc will be sent to the Portal if the business party = TRUE.' },
                    ]}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef6 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef6);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef6)}
                    supportedFormats="Supports: .doc, .xls, .pdf, .jpg, .txt, .png"
                    additionalInfoText="Each file must be less than 50MB. The total size of all files cannot be greater than 100MB."
                    type="compact"
                />
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React, { Component } from 'react';
import { FileUploadCard } from '@jkhy/vsg-design-system';

class Example extends Component {

    private fileUploadCardRef1 = null;
    private fileUploadCardRef2 = null;
    private fileUploadCardRef3 = null;

    private fileUploadCardRef4 = null;
    private fileUploadCardRef5 = null;
    private fileUploadCardRef6 = null;

    constructor(props: any) {
        super(props);
        this.state = {
            files: [
                {
                    file: new File(["file-from-db"], "file-from-db-uploaded.txt", {
                        type: "text/plain",
                    }),
                    uploadSuccessful: true,
                    guid: 'test-guid'
                },
                {
                    file: new File(["file-from-db-two"], "file-from-db-loading.txt", {
                        type: "text/plain",
                    }),
                    uploadSuccessful: false,
                    loading: true,
                },
                {
                    file: new File(["file-from-db-three"], "file-from-db-random-loading-progress.txt", {
                        type: "text/plain",
                    })
                },
            ] as {
                file: File,
                error: string,
                progress: number,
                uploadSuccessful: boolean,
                errorDescription: string,
                loading: boolean,
            }[],
        };
    }

    componentDidMount() {
        const copyState = { ...this.state } as any;
        if (copyState.files) {
            this.simulateUploading(copyState.files, this.fileUploadCardRef1);
        }

    }

   
    simulateUploading = (files: any, ref: any) => {

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.uploadSuccessful) {
                continue;
            }

            if (file.error) {
                const message = this.getErrorMessage(file.error, ref);

                // If file contains error call this function
                ref.onFileError(file, message.title, message.desc);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = file.progress || 0;

                let interval  = null as any;
                if (file.loading) {
                    ref.updateLoading(file, file.loading);
                }else{
                     interval = setInterval(() => {
                        progress += 1;
                        if (progress <= 100) {
                            if (!file.loading) {
                                ref.updateProgress(file, progress);
                            }
                        }
    
                    }, time / 100)
                }

                // If there error while uploading call this function
                // ref.onFileError(file, message.title, message.desc);

                setTimeout(() => {
                    clearInterval(interval);

                    // If success call this function to set file success
                    // ref.updateLoading(file, false);
                    ref.onFileSuccess(file, "{file.file.name}-guid");
                }, time)
            }
        }

    }

    // Set custom Error messages
    getErrorMessage = (type: any, ref: any) => {
     switch (type) {
         case 'invalid name':
             return { title: 'Invalid file name.' }
         case 'exceeded maximum size':
             return { title: "File exceeds maximum file size ({ref.props.maxFileSizeMB || 50}MB.)" };
         case 'invalid type':
             return { title: 'Invalid file type.' }
         case 'pdf file is password protected':
             return { title: 'File is password protected', desc: 'Uploaded file appears to have a password protection. Please upload a file that is not password protected.' }
         default:
             return { title: 'Failed to upload' };
     }
 }

    handleOnChange = (files: any, ref: any, saveToState = false) => {
        const copyState = { ...this.state } as any;
        const mappedFiles = files.map((f:any)=> {return {...f, loading: Math.floor(Math.random() * 2) === 1 }})
        let newFiles = files;
        if (saveToState) {
            newFiles = mappedFiles;
            this.setState({
                files: [...copyState.files, ...newFiles],
            })
        }
        this.simulateUploading(newFiles, ref);
    }

    handleOnSubmit = (ref: any, demoError?: boolean) => {
        // Get the files

        // Simulated submit time
        setTimeout(() => {
            if (demoError) {
                // If error call this function with error message
                ref.onSubmitError('Internal Server Error please try again.');
            } else {
                // If success call this function
                ref.onSubmitSuccess('File was submitted.');
            }
        }, 2000)
    }


    render() {

        return (
            <div style={{ maxWidth: '383px' }}>
                <FileUploadCard
                    files={files}
                    className="mb-3"
                    title="Recurring document title goes here"
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    onRef={(ref: any) => (this.fileUploadCardRef1 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef1, true);
                    }}
                    onDelete={(file: any, removeWithAnimation: any) => {
                        const ref = this.fileUploadCardRef1 as any;

                        ref.updateLoading(file, true);
                        setTimeout(() => {
                            console.log('DELETED FILE', file);
                            ref.updateLoading(file, false);

                            const updateOutherState = () => {
                                const { files } = this.state as any;
                                const saveFiles = [...files];
                                const currentFile = saveFiles.find((f) => f.file === file.file);
                                const currentFileIndex = saveFiles.indexOf(currentFile);
                                saveFiles.splice(currentFileIndex, 1);
    
                                this.setState({
                                    files: [...saveFiles]
                                });
                            }
                            removeWithAnimation(updateOutherState);
                        }, 5000);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef1)}


                    // Set this to get "COMPACT" view
                    type="compact"

                    // Set this to remove error files automatically
                    removeOldErrorFiles

                    // Set text after supportedFormats text
                    additionalInfoText='Each file must be less than 50MB. The total size of all files cannot be greater than 100MB.'
                />

                <FileUploadCard
                    className="mb-3"
                    title="Recurring document with error on submit"
                    details={[
                        { title: 'Required for:', maxVisibleTexts: 4, text: [
                            'Randall Enterprises',
                            'Randall Enterprises Holdings, L.L.C.',
                            'Allen Meriwether. Jr. Living Trust III, L.L.C.',
                            'Jacqueline Gladys Meriwether Trust L.L.C.',
                            'Allen Meriwether. Jr. Living Trust III',
                            'Allen Meriwether. Jr. 123',
                        ] },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef2 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef2);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef2, true)}
                      
                    // Set this to get "COMPACT" view
                    type="compact"
                />

                <FileUploadCard
                    className="mb-3"
                    cardErrorMessage="Reccuring Document was declined. Please upload a new file."
                    title="LV document"
                    details={[
                        { title: 'Declined on:', text: '15/10/2019', isInvalid: true },
                        { title: 'Required for:', text: 'FMDEF LS TEST CZAR' },
                        { title: 'Description:', text: 'This pre. doc will be sent to the Portal if the business party = TRUE.' },
                    ]}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef3 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef3);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef3)}

                    supportedFormats="Supports: .doc, .xls, .pdf, .jpg, .txt, .png"

                    // Set this to get "COMPACT" view
                    type="compact"
                />
            </div>
        );
    }
}
export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <PropVersionTag type="new" version="4.3" />
                            files
                        </td>
                        <td>
                            array of objects: {'{'}<br />
                            "file": File,<br />
                            "error"?: string,<br />
                            "errorDescription"?: string,<br />
                            "uploadSuccessful"?: boolean,<br />
                            "progress"?: number,<br />
                            "loading"?: boolean,<br />
                            "guid"?: string,<br />
                            {'}'}
                        </td>
                        <td className="text-center">-</td>
                        <td>Set card files.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title to the card.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> cardErrorMessage</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Shows alert with error message.</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>array of objects with keys: "text", "maxVisibleTexts", "path", "isInvalid"</td>
                        <td className="text-center">-</td>
                        <td>Set details to the card.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> isMultiple</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Specifies that the user is allowed to enter more than one file.</td>
                    </tr>
                    <tr>
                        <td>maxFileSizeMB</td>
                        <td>number</td>
                        <td>50</td>
                        <td>Set maximum file size to upload in <strong>MB</strong>.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> removeOldErrorFiles</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>When drop new file will remove old files with error.</td>
                    </tr>
                    <tr>
                        <td>onRef</td>
                        <td>(ref) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>Callback who return reference to 'this' FileUploadCard.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> onChange</td>
                        <td>(files: {'{'} file: File, error: string {'}'}[]) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>
                            Callback when user upload file. Returns array with objects. Object contains 'file' and 'error'.
                            <br />
                            <br />
                            Errors: 'invalid name', 'exceeded maximum size', 'invalid type' or null
                        </td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.3" /> onDelete</td>
                        <td>(file:{'{'}file:File, error?: string, progress?: number, uploadSuccessful?: boolean, loading?: boolean {'}'}, removeRowWithAnimation : (callback){'=>{}'}) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>
                            Callback when user delete file. Returns deleted file data and function wich delete the file with animation.
                            <br />
                            <br />
                            Errors: 'invalid name', 'exceeded maximum size', 'invalid type' or null
                        </td>
                    </tr>
                    <tr>
                        <td>onSubmit</td>
                        <td>(files: {'{'} file: File, error: string {'}'}[]) {'=>'} void</td>
                        <td className="text-center">-</td>
                        <td>Callback for when the "submit" button is clicked.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.3" />type</td>
                        <td>Enum with options: 'default' || 'compact'</td>
                        <td className="text-center">'default'</td>
                        <td>Set component look.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.3" />supportedFormats</td>
                        <td>string</td>
                        <td className="text-center">'Supports: .doc, .xls, .pdf, .jpg'</td>
                        <td>Set supported formats text.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version='4.7'/> additionalInfoText</td>
                        <td>string</td>
                        <td>Set text after supportedFormats text.</td>
                    </tr>
                </tbody>
            </table>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Ref Methods</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Props</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" /> onFileSuccess</td>
                        <td>fileObj: {'{'} file: File {'}'}</td>
                        <td>Method which set success styles to file. Execute when file is success uploaded.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> onFileError</td>
                        <td>fileObj: {'{'} file: File {'}'}, message: string, description: string</td>
                        <td>Method which set error styles to file. Execute when file contains error or upload is failing.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> updateProgress</td>
                        <td>fileObj: {'{'} file: File {'}'}, progress: number</td>
                        <td>Method which update the progress bar for given file.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version='4.3' /> updateLoading</td>
                        <td>fileObj: {'{'} file: File {'}'}, loading: boolean</td>
                        <td>Method which update the loading state for given file.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> onSubmitSuccess</td>
                        <td>
                            message: string<br />
                            dontRemove: boolean<br />
                        </td>
                        <td>Method which sets success styles and message to FileUploadCard and hide it after time. <br />
                            <strong>default message:</strong> "Your documents were submitted."
                        </td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> onSubmitError</td>
                        <td>message: string</td>
                        <td>Method which set error message to FileUploadCard.</td>
                    </tr>
                </tbody>
            </table>

        </div>;
    }
}

export default FileUploadCardDocs;
