import React from 'react';

const Introduction = () => (
  <div>
    <h1 className="mb-3">Introduction</h1>
    <p className="mb-1">VSG Design System is a React component library, enhanced with an adjacent CSS framework. The main goal
    of VSG Design System is to provide components, structures and guidelines for consistent UIs.
    </p>
    <p className="mb-5">A key part of the successful integration of VSG Design System is to provide an
      environment <b>without any additional style rules</b>. If there are outer/global CSS rules and
      variables, this would potentially break the design system. Even if those outer styles are not global, but
      contain specific class or id selectors, it is still not advisable to use such stylesheets. For example,
      a CSS framework like Bootstrap would clash with VSG Design System, since many of the utilities
      in the CSS framework of the design system have matching names with those of Bootstrap.
    </p>

    <h2 className="mb-3">VSG Design System Explicit Scope</h2>
    <p className="mb-5">It is crucial to add the specific CSS scoping class to the <i>body</i> element: <strong>"qd-design-system"</strong>. This will
    allow the design system styles to be applied to the content of the page.</p>

    <h2 className="mb-3">Modules and Styles</h2>
    <p className="mb-5">The VSG Design System npm package contains JavaScript modules with TypeScript definitions for each of the components, as well as
    a stylesheet, which is the CSS framework.</p>

    <h2 className="mb-3">Customization</h2>
    <p className="mb-1">In its core, the CSS framework of VSG Design System contains CSS variables, which
    shape the general feeling of the UIs created by it. It is not advisable to override styling rules, because
    this would break the design system consistency. However, a set of core CSS variables is exposed, in order
    to allow extensibility and customization.</p>
    <p className="mb-1">Customizable CSS variables:</p>
    <ul className="basic-list mb-5">
      <li><b>--font-family</b> - The main font family. <a href="#available-fonts">Available fonts</a>.</li>
      <li><b>--html-font-size</b> - This is the font size of the document. The components in the design system
        are created with relative units (rems), so this variable has crucial impact on scaling.
      </li>
      <li><b>--size-components</b> - Coefficient of component scale.</li>
      <li><b>--size-spacing</b> - Coefficient of spacing between components scale.</li>
      <li><b>--roundness</b> - Coefficient of border radiuses for components.</li>
      <li><b>--layout-roundness</b> - Coefficient of border radiuses for layout components.</li>
      <li><b>--color-primary</b> - While most of the colors are predefined by their names, the primary color is always unique
      and is the accent of the whole system</li>
      <li><b>--color-select</b></li>
      <li><b>--color-text</b></li>
      <li><b>--color-text-muted</b></li>
      <li><b>--color-text-disabled</b></li>
      <li><b>--color-1</b></li>
      <li><b>--color-2</b></li>
      <li><b>--color-3</b></li>
      <li><b>--color-4</b></li>
      <li><b>--color-success</b></li>
      <li><b>--color-warning</b></li>
      <li><b>--color-danger</b></li>
      <li><b>--color-select-success</b></li>
      <li><b>--color-select-warning</b></li>
      <li><b>--color-select-danger</b></li>
      <li><b>--header-color</b></li>
      <li><b>--header-text-color</b></li>
      <li><b>--shadow-sm</b></li>
      <li><b>--shadow-md</b></li>
      <li><b>--shadow-lg</b></li>
      <li><b>--shadow-glow-primary</b></li>
      <li><b>--shadow-glow-success</b></li>
      <li><b>--shadow-glow-error</b></li>
      <li><b>--background-image</b> - The body background image.</li>
      <li><b>--header-logo</b> - The header logo.</li>
      <li><b>--header-logo-height</b> - The height of the logo in the header.</li>
      <li><b>--header-logo-margin</b> - The margin shorthand values of the logo in the header.</li>
      <li><b>--loan-req-commercial-title</b> - Text content for the title of LoanRequestBox of type "commercial".</li>
      <li><b>--loan-req-commercial-description</b> - Text content for the description of LoanRequestBox of type "commercial".</li>
      <li><b>--loan-req-commercial-cta</b> - Text content for the call-to-action button of LoanRequestBox of type "commercial".</li>
      <li><b>--loan-req-consumer-title</b> - Text content for the title of LoanRequestBox of type "consumer".</li>
      <li><b>--loan-req-consumer-description</b> - Text content for the description of LoanRequestBox of type "consumer".</li>
      <li><b>--loan-req-consumer-cta</b> - Text content for the call-to-action of LoanRequestBox of type "consumer".</li>
    </ul>

    <h2 className="mb-3" id="available-fonts">Available fonts</h2>
    <ul className="basic-list mb-5">
      <li>Nunito: <b>'Nunito', sans-serif</b></li>
      <li>IBM Plex Serif: <b>'IBM Plex Serif', serif</b></li>
      <li>Merriweather: <b>'Merriweather', serif</b></li>
      <li>Montserrat: <b>'Montserrat', sans-serif</b></li>
      <li>Roboto: <b>'Roboto', sans-serif</b></li>
      <li>Barlow: <b>'Barlow', sans-serif</b></li>
      <li>Cabin: <b>'Cabin', sans-serif</b></li>
    </ul>

    <h2 className="mb-3">Layout arrangement</h2>
    <p className="mb-5">VSG Design System is aiming to allow UI creation with minimal effort. Having in mind
    its components and the CSS framework, there is one more thing that is essential for keeping consisteny
    among UIs - the utility classes. All layout variations should be handled only with the tools of VSG Design System.
    If there is a scenario when additional CSS is needed, it should be seriously reconsidered and a design
    review should be done.</p>
  </div>
);

export default Introduction;